<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Job Order</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-1"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="jobOrderForm" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col cols="12" :md="openSection ? '9' : '12'">
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <h2>
                              Job Order Information
                              <span v-if="+$route.query.id">
                                -
                                <strong>
                                  {{ formData.preview_job_status }}
                                  ({{ formData.job_name }})
                                </strong>
                              </span>
                            </h2>

                            <span v-if="+$route.query.id">
                              <b-button-group size="sm">
                                <b-button
                                  @click="onUpdateJobStatus('0')"
                                  variant="primary"
                                >
                                  New
                                </b-button>
                                <b-button
                                  @click="onUpdateJobStatus('1')"
                                  variant="info"
                                >
                                  Accepted
                                </b-button>
                                <b-button
                                  @click="onUpdateJobStatus('2')"
                                  variant="warning"
                                >
                                  Work in progress
                                </b-button>
                                <b-button
                                  @click="onUpdateJobStatus('3')"
                                  variant="danger"
                                >
                                  Paused
                                </b-button>
                                <b-button
                                  @click="onUpdateJobStatus('4')"
                                  variant="success"
                                >
                                  Done
                                </b-button>
                                <b-button
                                  @click="onUpdateJobStatus('5')"
                                  variant="secondary"
                                >
                                  Completed
                                </b-button>
                              </b-button-group>
                            </span>
                          </b-form-group>
                        </b-col>

                        <b-col class="text-right" v-if="!$route.query.id">
                          <ContractsSearch
                            class="mr-1"
                            :company-id="formData.company_id"
                            v-if="formData.company_id"
                            @on-select="(v) => onSelectContract(v)"
                          />
                          <CompanySearch
                            @on-select="(v) => onSelectCompany(v)"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Ticket No."
                            label-for="v-ticket-no"
                            disabled
                          >
                            <b-form-input
                              disabled
                              v-model="formData.ticket_no"
                              id="v-ticket-no"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                          <b-form-group label="Company" label-for="v-company">
                            <b-form-input
                              maxlength="60"
                              :disabled="+$route.query.id > 0"
                              v-model="formData.company_name"
                              id="v-company"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group
                            label="Contract No."
                            label-for="v-contract_refnum"
                          >
                            <b-form-input
                              list="contractLists"
                              v-model="formData.contract_refnum"
                              id="v-contract_no"
                              @change="onSelectContract($event)"
                              autocomplete="off"
                            />

                            <datalist id="contractLists">
                              <option
                                v-for="(contract, index) in contractItems"
                                :key="index"
                                :value="contract.value"
                              >
                                {{ contract.text }}
                              </option>
                            </datalist>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group label="Po No." label-for="v-po_no">
                            <b-form-input
                              v-model="formData.po_no"
                              id="v-po_no"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group label="Job Name" label-for="v-job">
                            <v-select
                              :disabled="+$route.query.id > 0"
                              id="v-job"
                              label="name"
                              :clearable="false"
                              v-model="formData.job_id"
                              :options="jobItems"
                              :reduce="(jobItems) => +jobItems.id"
                              @option:selected="onSelectJob($event)"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group label="Job Type" label-for="v-company">
                            <b-form-input
                              v-model="formData.job_type"
                              id="v-company"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Status"
                            label-for="v-company"
                          >
                            <!-- <b-form-input
                              disabled
                            /> -->
                            <b-select
                              v-model="formData.preview_job_status"
                              id="v-job_status"
                              :options="[
                                { text: 'New', value: '0' },
                                { text: 'Accepted', value: '1' },
                                { text: 'Work in progress', value: '2' },
                                { text: 'Paused', value: '3' },
                                { text: 'Done', value: '4' },
                                { text: 'Completed', value: '5' },
                              ]"
                            ></b-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group label-for="v-email">
                            <template v-slot:label>
                              Recipients <span class="text-danger">*</span>
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <b-form-input
                                maxlength="60"
                                v-model="formData.email"
                                :state="errors.length > 0 ? false : null"
                                type="email"
                                placeholder="Email"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="2">
                          <b-form-group
                            label="Report Date"
                            label-for="v-report_date-date"
                          >
                            <b-form-input
                              disabled
                              id="v-report_date-date"
                              v-model="formData.preview_report_date"
                              type="datetime-local"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Scheduled Expected Arrival"
                            label-for="v-job_eta"
                          >
                            <b-form-input
                              id="v-job_eta"
                              v-model="formData.preview_job_eta"
                              @change="loadAvailableTechnicians()"
                              type="datetime-local"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Scheduled Expected Departure"
                            label-for="v-job_etd"
                          >
                            <b-form-input
                              id="v-job_etd"
                              v-model="formData.preview_job_etd"
                              @change="loadAvailableTechnicians()"
                              type="datetime-local"
                              :class="{ 'border-danger': errDateRange }"
                              class="form-control"
                            />
                            <small class="text-danger" v-if="errDateRange">
                              <strong>Job Expected Departure</strong> must not
                              be less than
                              <strong>Job Expected Arrival</strong>
                            </small>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group
                            label="Chargeable Amount"
                            label-for="v-chargeable_amount"
                          >
                            <b-form-input
                              class="text-right"
                              disabled
                              v-model="formData.chargeable_amount"
                              id="v-chargeable_amount"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group
                            label="New Chargeable Amount"
                            label-for="v-post_amount"
                          >
                            <b-form-input
                              class="text-right"
                              v-model="formData.post_amount"
                              id="v-post_amount"
                              @change="convertToDecimal"
                              @keypress="isNumber"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" md="6">
                          <b-form-group
                            label="Job Started"
                            label-for="v-job_ata"
                          >
                            <b-form-input
                              disabled
                              id="v-job_ata"
                              v-model="formData.preview_job_ata"
                              type="datetime-local"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                          <b-form-group label="Job Ended" label-for="v-job_atd">
                            <b-form-input
                              disabled
                              id="v-job_atd"
                              v-model="formData.preview_job_atd"
                              type="datetime-local"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Assignment Information</h2>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Primary Technician"
                            label-for="v-sales-person"
                          >
                            <v-select
                              v-model.number="formData.job_assigned_to_1"
                              :options="employeeItems"
                              :reduce="(employeeItems) => employeeItems.value"
                              @option:selected="onSelectTechnician($event)"
                            >
                              <template #option="{ label, has_jobs }">
                                <div :class="has_jobs > 0 ? 'text-danger' : ''">
                                  {{ label }}
                                </div>
                              </template>
                            </v-select>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Secondary Technician"
                            label-for="v-sales-person"
                          >
                            <v-select
                              id="v-technician_id"
                              v-model.number="formData.job_assigned_to_2"
                              :options="employeeItems"
                              :reduce="(employeeItems) => employeeItems.value"
                              @option:selected="onSelectTechnician($event)"
                            >
                              <template #option="{ label, has_jobs }">
                                <div :class="has_jobs > 0 ? 'text-danger' : ''">
                                  {{ label }}
                                </div>
                              </template>
                            </v-select>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Crew Calendar"
                            label-for="v-calendar"
                          >
                            <CrewCalendar
                              id="v-calendar"
                              :technician="selectedTechnician"
                              :current-date="currentDate"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Problem Description"
                            label-for="v-problem_description"
                          >
                            <b-form-textarea
                              id="v-problem_description"
                              v-model="formData.problem_description"
                              rows="4"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Job Description"
                            label-for="v-job_description"
                          >
                            <quill-editor
                              ref="quillEditorRef"
                              v-model="jobInstructions"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="6">
                          <h2>Job Site</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <span v-if="!isHidingForm">
                            <CompanyAddressSearch
                              v-if="formData.company_id"
                              :company_id="formData.company_id"
                              @on-select="(v) => onSelectCompanyAddress(v)"
                            />
                          </span>

                          <feather-icon
                            @click="isHidingForm = !isHidingForm"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingForm,
                              arrow_up: !isHidingForm,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingForm">
                        <b-row>
                          <b-col>
                            <b-form-group label-for="v-site_id">
                              <template v-slot:label>
                                Site/Branch/Outlet Name
                                <span class="text-danger">*</span>
                              </template>
                              <validation-provider
                                #default="{ errors }"
                                name="Site ID"
                                rules="required"
                              >
                                <b-form-input
                                  maxlength="60"
                                  v-model="formData.site_id"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="2">
                            <b-form-group
                              label="Service Type"
                              label-for="v-contract_type"
                            >
                              <v-select
                                id="v-contract_type"
                                v-model="formData.contract_type"
                                :options="[
                                  'project',
                                  'installation',
                                  'maintenance',
                                  'chargeable',
                                ]"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="2">
                            <b-form-group
                              label="Service Location"
                              label-for="v-service_location"
                            >
                              <b-form-select
                                id="v-service_location"
                                v-model="formData.service_location"
                                :options="[
                                  { text: 'On-Site', value: 'O' },
                                  { text: 'Remote', value: 'R' },
                                ]"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Building/Block/House No. and Street Name"
                              label-for="v-addr1"
                            >
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  maxlength="120"
                                  v-model="formData.addr1"
                                  id="v-addr1"
                                />
                                <b-input-group-append
                                  is-text
                                  @click="isShowingMap = !isShowingMap"
                                >
                                  <feather-icon class="pin" icon="MapPinIcon" />
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Unit No. and Building Name"
                              label-for="v-addr2"
                            >
                              <b-form-input
                                maxlength="120"
                                v-model="formData.addr2"
                                id="v-addr2"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group label="Country" label-for="v-country">
                              <v-select
                                id="v-country"
                                v-model="formData.country"
                                :options="
                                  $store.getters['hbaseGeneral/getAllCountries']
                                "
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              maxlength="120"
                              label="Postal Code"
                              label-for="v-postal"
                            >
                              <b-form-input
                                v-model="formData.postal"
                                id="v-postal"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <ModalMap
                          :showMap="isShowingMap"
                          @onChangePlace="(v) => getPlace(v)"
                        />
                      </span>

                      <b-row>
                        <b-col cols="6">
                          <h2>Contact Information</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <feather-icon
                            @click="isHidingFormContact = !isHidingFormContact"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingFormContact,
                              arrow_up: !isHidingFormContact,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingFormContact">
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Contact Person"
                              label-for="v-poc"
                            >
                              <b-form-input v-model="formData.poc" id="v-poc" />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group label="Mobile" label-for="v-mobile">
                              <b-form-input
                                v-model="formData.mobile"
                                id="v-mobile"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </span>

                      <b-row>
                        <b-col>
                          <h2>Propose spares needed</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderSpares :data-source="dataSourceSpares" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Item(s) for Servicing</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderDetails :data-source="dataSourceDetails" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Customer's (Problem) Photos</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobOrderImages :data-source="dataSourceImages" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Checklists</h2>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Technician Checklists"
                            label-for="v-technician"
                          >
                            <v-select
                              id="v-technician"
                              v-model.number="formData.technician_checklist_id"
                              :options="checklists"
                              :reduce="(checklist) => +checklist.value"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Customer Checklists"
                            label-for="v-customer"
                          >
                            <v-select
                              id="v-customer"
                              v-model.number="formData.customer_checklist_id"
                              :options="checklists"
                              :reduce="(checklist) => +checklist.value"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="6">
                          <h2>Other Information</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <feather-icon
                            @click="isHidingFormRemarks = !isHidingFormRemarks"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingFormRemarks,
                              arrow_up: !isHidingFormRemarks,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingFormRemarks"
                        ><b-row>
                          <b-col cols="12">
                            <b-form-group label="GST" label-for="v-gst">
                              <v-select
                                id="v-gst"
                                v-model="formData.gst"
                                :options="gstItems"
                                :reduce="(gstItems) => gstItems.value"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              label="Instructions/Remarks"
                              label-for="v-remarks"
                            >
                              <b-form-textarea
                                v-model="formData.remarks"
                                id="v-remarks"
                                rows="4"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="returnToPage"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid || isLoading"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs ref="logsRef" module="joborder" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments ref="commentsRef" module="joborder" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import contractsApi from '@api/contract_headers';
import jobOrderApi from '@api/joborder_headers';
import usersApi from '@api/users';
import gstsApi from '@api/gsts';
import jobsApi from '@api/jobs';
import checklistsApi from '@api/checklist_template_headers';
import { required, email } from '@validations';
// @todo change from utils classes to global-function
import {
  addToTime,
  nowToLocaleDateString,
  UtilsDate,
} from '@/utils/classes/dates';
import { queryParameters } from '@/schema';
import JobOrderDetails from './components/JobOrderDetails';
import JobOrderImages from './components/JobOrderImages';
import JobOrderSpares from './components/JobOrderSpares';
import JobOrderCustomerChecklists from './components/JobOrderCustomerChecklists';
import JobOrderTechnicianChecklists from './components/JobOrderTechnicianChecklists';
import JobOrderLogs from './components/JobOrderLogs';
import JobOrderComments from './components/JobOrderComments';
import CrewCalendar from './components/CrewCalendar';

export default {
  name: 'JobOrderManage',
  components: {
    JobOrderDetails,
    JobOrderImages,
    JobOrderSpares,
    JobOrderCustomerChecklists,
    JobOrderTechnicianChecklists,
    JobOrderLogs,
    JobOrderComments,
    CrewCalendar,
  },
  data: () => ({
    required,
    email,
    success: false,
    openSection: false,
    errors: {},
    activeTab: 0,
    isLoading: false,
    isShowingMap: false,
    isHidingForm: false,
    isHidingFormContact: false,
    isHidingFormRemarks: false,
    dataSourceDetails: [],
    dataSourceImages: [],
    dataSourceSpares: [],
    dataSourceCustomerChecklists: [],
    dataSourceTechnicianChecklists: [],
    formData: {
      id: null,
      company_id: null,
      company_name: null,
      customer_email: null,
      job_id: null,
      refnum: null,
      job_type: null,
      job_status: '0',
      site_id: null,
      problem_description: null,
      report_date: null,
      urgency_level: null,
      poc: null,
      mobile: null,
      tel: null,
      email: null,
      designation: null,
      ticket_no: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      remarks: null,
      contract_refnum: null,
      po_no: null,
      fax: null,
      job_assigned_to_1: null,
      customer_contact: null,
      customer_name: null,
      customer_email: null,
      gst: 'SGST',
      post_amount: '0.00',
      chargeable_amount: '0.00',

      preview_report_date: nowToLocaleDateString,
      preview_job_eta: nowToLocaleDateString,
      preview_job_etd: nowToLocaleDateString,
      preview_job_ata: null,
      preview_job_atd: null,
    },
    employeeItems: [],
    jobItems: [],
    gstItems: [],
    contractItems: [],
    checklists: [],
    selectedTechnician: null,
    currentDate: new Date(),
    jobInstructions: '',
    errDateRange: false,
  }),
  watch: {
    activeTab(index) {
      if (index === 1) {
        this.$refs.logsRef.loadLogs();
      }

      if (index === 2) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
    section(v) {
      this.openSection = v;
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.formData.id ? 'Update' : 'Create';
      },
    },
    serviceLocation: {
      get() {
        const { service_location } = this.formData;

        return service_location === 'O' ? 'On-Site' : 'Remote';
      },
    },
  },
  mounted() {
    this.formData.preview_report_date = this.inputDateTimeToLocale();
    this.formData.preview_job_eta = this.defaultDateTime();
    this.formData.preview_job_etd = this.defaultDateTime();

    this.loadAvailableTechnicians();
    this.loadJobs();
    this.loadGSTS();
    this.loadChecklists();

    if (+this.$route.query.id) {
      this.loadJobOrder();
    }
  },
  methods: {
    loadGSTS() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstsApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const { name, description, percent } = item;

            items.push({
              label: `${description} - ${percent}%`,
              value: name,
              item,
            });
          }

          this.gstItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadAvailableTechnicians() {
      const etd = new Date(this.formData.preview_job_etd);
      const eta = new Date(this.formData.preview_job_eta);

      this.isLoading = eta > etd;
      this.errDateRange = eta > etd;

      this.employeeItems = [{ label: 'N/A', value: 0 }];

      const replace = (data) => {
        let date = data.replace('T', ' ');
        date += ':00.000 +0800';

        return date;
      };

      this.currentDate = new Date(this.formData.preview_job_eta);

      const job_eta = replace(this.formData.preview_job_eta);
      const job_etd = replace(this.formData.preview_job_etd);

      const params = {
        filterExt: `from_to=${job_eta}|${job_etd}`,
      };

      usersApi
        .getAvailableTechnicians(params)
        .then(({ data }) => {
          for (const item of data) {
            const { id, first_name, last_name, username, has_jobs } = item;

            let fullName = first_name || username;
            if (first_name && last_name) {
              fullName += ` ${last_name}`;
            }

            this.employeeItems.push({
              label: fullName,
              value: parseInt(id),
              has_jobs,
              item,
            });
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobs() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      jobsApi
        .list(params)
        .then(({ data }) => {
          this.jobItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadChecklists() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      checklistsApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (const item of data) {
            items.push({ label: item.title, value: +item.id, item });
          }

          this.checklists = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadContracts(company_id) {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `company_id=${company_id}`,
      };

      contractsApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            const items = [];
            for (const item of data) {
              const { refnum, contract_type } = item;
              items.push({ value: refnum, text: refnum, contract_type });
            }

            this.contractItems = items;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobOrder() {
      jobOrderApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data) {
            const {
              report_date,
              job_eta,
              job_etd,
              job_ata,
              job_atd,
              job_status,
              job_id,
              job_instructions,
              job_assigned_to_1,
              joborder_jobrequest_header: jh,
              post_amount,
              chargeable_amount,
            } = data;

            const toDisplayDate = (date) => {
              if (date) {
                return new UtilsDate(date).unixToInputDateTime();
              }
            };

            const getDetails = (details) => {
              if (details && details.data) {
                return details.data;
              }

              return [];
            };

            const { quill } = this.$refs.quillEditorRef;
            if (quill) {
              quill.root.innerHTML = job_instructions;
            }

            data.preview_job_status = this.getJobStatus(job_status);

            data.preview_report_date = toDisplayDate(report_date);
            data.preview_job_eta = toDisplayDate(job_eta);
            data.preview_job_etd = toDisplayDate(job_etd);
            data.preview_job_ata = toDisplayDate(job_ata);
            data.preview_job_atd = toDisplayDate(job_atd);

            if (post_amount) {
              data.post_amount = post_amount.toFixed(2);
            }

            if (chargeable_amount) {
              data.chargeable_amount = chargeable_amount.toFixed(2);
            }

            if (job_id && data.joborder_job.data) {
              data.job_name = data.joborder_job.data.name;
            }

            if (jh && jh.data) {
              const site = jh.data;
              data.addr1 = site.addr1;
              data.addr2 = site.addr2;
              data.country = site.country;
              data.postal = site.postal;
            }

            const user = this.employeeItems.find((x) => {
              return x.value === job_assigned_to_1;
            });

            this.selectedTechnician = {
              id: job_assigned_to_1,
              display_name: user.label,
            };

            this.dataSourceDetails = getDetails(data.joborder_details);
            this.dataSourceImages = getDetails(data.joborder_images);
            this.dataSourceSpares = getDetails(data.joborder_spares);
            this.dataSourceCustomerChecklists = getDetails(
              data.joborder_customer_checklists
            );
            this.dataSourceTechnicianChecklists = getDetails(
              data.joborder_technician_checklists
            );

            const job = this.jobItems.find((x) => {
              return +x.id === data.job_id;
            });

            if (job) {
              const { standard_time } = job;

              data.hrs = Math.floor(standard_time / 60);
              data.mins = standard_time % 60;
            }

            this.loadContracts(data.company_id);
            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      this.isLoading = true;
      const success = () => {
        window.scrollTo(0, 0);
        this.success = true;
        this.toastConfig();
        this.loadJobOrder();
      };

      const error = () => {
        window.scrollTo(0, 0);
        this.toastConfig('', 'error');
        this.success = false;
        this.errors = err;
      };

      const replace = (data) => {
        if (data) {
          return Number(data.replace(/[^0-9.-]+/g, ''));
        }
      };

      const data = this.formData;

      data.job_instructions = this.jobInstructions;
      data.report_date = this.inputDateTimeToUnix(data.preview_report_date);
      data.job_eta = this.inputDateTimeToUnix(data.preview_job_eta);
      data.job_etd = this.inputDateTimeToUnix(data.preview_job_etd);
      data.chargeable_amount = replace(data.chargeable_amount);
      data.post_amount = replace(data.post_amount);

      const getDetails = (dataSource, opt = { addOn: false }) => {
        const details = [];
        for (let i = 0; i < dataSource.length; i++) {
          const detail = dataSource[i];
          if (opt.addOn) {
            if (typeof detail.id !== 'number') {
              detail.reference_id = detail.id;
            }
          }

          details.push(detail);
        }

        return details;
      };

      data.details = getDetails(this.dataSourceDetails, { addOn: true });
      data.images = getDetails(this.dataSourceImages, { addOn: true });
      data.spares = getDetails(this.dataSourceSpares);
      data.customer_checklists = getDetails(this.dataSourceCustomerChecklists);
      data.technician_lists = getDetails(this.dataSourceTechnicianChecklists);

      let app = null;

      if (!data.id) {
        app = jobOrderApi.add(data);
      } else {
        app = jobOrderApi.update(data);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.isLoading = false;
            const url = `/pages/services-tools/job-order/view?id=${data.id}`;
            this.$router.push(url);
            success();
          })
          .catch((err) => {
            error();
          })
          .finally(() => {
            //
          });
      }
    },
    returnToPage() {
      this.$router.push(`/pages/services-tools/job-order`);
    },
    getPlace(place) {
      const data = this.formData;
      const { name, country, postal, lat, lng } = place;

      data.addr1 = name;
      data.country = country || 'Singapore';
      data.postal = postal;
      data.latitude = parseFloat(lat || 0);
      data.longitude = parseFloat(lng || 0);
    },
    onSelectCompany(e) {
      if (e) {
        const data = this.formData;
        this.loadContracts(e.id);

        data.company_id = +e.id;
        data.company_name = e.company_name;

        const { company_contacts, company_locations } = e;

        if (company_locations && company_locations.data) {
          const locations = company_locations.data;

          if (locations.length > 0) {
            const [location] = locations;
            const {
              site_id,
              addr1,
              addr2,
              country,
              postal,
              latitude,
              longitude,
            } = location;

            data.site_id = site_id;
            data.addr1 = addr1;
            data.addr2 = addr2;
            data.country = country;
            data.postal = postal;
            data.latitude = parseFloat(latitude || 0);
            data.longitude = parseFloat(longitude || 0);
          } else {
            data.site_id = null;
            data.addr1 = null;
            data.addr2 = null;
            data.country = 'Singapore';
            data.postal = null;
            data.latitude = 0;
            data.longitude = 0;
          }
        }

        if (company_contacts && company_contacts.data) {
          const contacts = company_contacts.data;

          if (contacts.length > 0) {
            const [contact] = contacts;
            const { first_name, last_name, designation, email, tel, mobile } =
              contact;

            data.poc = `${first_name} ${last_name}`;
            data.designation = designation;
            data.email = email;
            data.tel = tel;
            data.mobile = mobile;
          } else {
            data.poc = null;
            data.designation = null;
            data.email = null;
            data.tel = null;
            data.mobile = null;
          }
        }
      }
    },
    onSelectCompanyAddress(item) {
      if (item) {
        const data = this.formData;

        data.site_id = item.site_id;
        data.addr1 = item.addr1;
        data.addr2 = item.addr2;
        data.country = item.country || 'Singapore';
        data.postal = item.postal;
      }
    },
    onSelectJob(data) {
      const { name, job_type, standard_price, standard_time, notes } = data;
      const { preview_job_eta } = this.formData;
      this.formData.job_name = name;
      this.formData.job_type = job_type;
      this.formData.chargeable_amount = this.moneyFormat(
        standard_price
      ).replace('$', '');
      this.formData.post_amount = this.moneyFormat(standard_price).replace(
        '$',
        ''
      );
      this.formData.hrs = Math.floor(standard_time / 60);
      this.formData.mins = standard_time % 60;
      this.formData.job_minutes = standard_time;
      this.formData.job_instructions = notes;

      const toUnix = (date) => {
        if (!date) {
          return new UtilsDate().dateToUnix();
        }

        return new UtilsDate(date).dateToUnix();
      };

      const addedTime = addToTime(toUnix(preview_job_eta), standard_time);

      const toDisplayDate = (date) => {
        return new UtilsDate(date).unixToInputDateTime();
      };

      this.formData.preview_job_etd = toDisplayDate(addedTime);
      this.loadAvailableTechnicians();
    },
    onSelectContract(data) {
      if (data) {
        const { refnum, contract_type } = data;
        this.formData.contract_refnum = refnum;
        this.formData.service_type = contract_type;
      }
    },
    onUpdateJobStatus(v) {
      const formData = {
        id: parseInt(this.$route.query.id),
        jobstatus: v,
      };

      jobOrderApi
        .updatejobstatus(formData)
        .then(({ data }) => {
          if (data) {
            const unix = new Date() / 1000;
            const displayDate = this.displayDateTimeFromUnix(unix);

            this.formData.preview_job_status = this.getJobStatus(v);

            if (v === '2') {
              this.formData.preview_job_ata = displayDate;
            }

            if (v === '5') {
              this.formData.preview_job_atd = displayDate;
            }

            this.isJobNew = ['0', '1'].includes(v);
            this.loadJobRequest();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSelectTechnician(data) {
      this.unAvailable = false;
      this.selectedTechnician = {
        id: 0,
        display_name: 'N/A',
      };

      if (data && data.item) {
        const { id, first_name, last_name, has_jobs } = data.item;

        if (has_jobs > 0) {
          this.unAvailable = true;
        }

        this.selectedTechnician = {
          id: parseInt(id),
          display_name: `${first_name} ${last_name}`,
        };
      }
    },
    convertToDecimal() {
      const { post_amount } = this.formData;

      if (post_amount != '' && this.correctMoneyFormat(post_amount)) {
        const toDecimal = this.moneyFormat(post_amount).replace('$', '');

        const valueIsNan = isNaN(toDecimal.replaceAll(',', ''));
        this.formData.post_amount = valueIsNan ? '0.00' : toDecimal;

        return;
      }

      this.formData.post_amount = 0;
    },
    isNumber(event) {
      if (isNaN(event.key) && event.key !== '.' && event.key !== 'Backspace') {
        event.preventDefault();
      }

      if (event.code === 'Space') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
