<template>
  <span>
    <DxScheduler
      time-zone="Asia/Singapore"
      :show-all-day-panel="false"
      :data-source="dataSource"
      :current-date="currentDate"
      :views="views"
      :groups="groups"
      :height="500"
      :start-day-hour="0"
      current-view="day"
      data-cell-template="dataCellTemplate"
      date-cell-template="dateCellTemplate"
      time-cell-template="timeCellTemplate"
      resource-cell-template="resourceCellTemplate"
      appointment-template="appointmentTemplate"
    >
      <DxResource
        :use-color-as-default="true"
        :data-source="jobs"
        field-expr="reference_id"
      />
      <DxResource
        :data-source="users"
        :allow-multiple="false"
        label="Employee"
        field-expr="job_assigned_to_1"
      />

      <template #resourceCellTemplate="{ data: user }">
        <b-row>
          <b-col>
            <div class="" style="margin-bottom: 7px">
              {{ user.data.display_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <template #dataCellTemplate="{ data: cellData }">
        {{ cellData.text }}
      </template>

      <template #dateCellTemplate="{ data: cellData }">
        <div class="">
          {{ cellData.text }}
        </div>
      </template>

      <template #timeCellTemplate="{ data: cellData }">
        <div class="">
          {{ cellData.text }}
        </div>
      </template>
      <template #appointmentTemplate="{ data: { appointmentData: formData } }">
        <div class="">
          <div class="">
            <strong>{{ formData.ticket_no }}</strong>
            ({{ formData.job_type }})
          </div>
          <div class="">
            {{ formData.company_name }} - {{ formData.address }}
          </div>
          <div class="">
            <small class="font-italic">
              {{ formData.preview_time_job_eta }} -
              {{ formData.preview_time_job_etd }}
            </small>
          </div>
        </div>
      </template>
    </DxScheduler>
  </span>
</template>

<script>
import jobOrdersApi from '@api/joborder_headers';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import {
  UtilsDate,
  unixToDisplayDateTime,
  unixToDisplayTime,
} from '@/utils/classes/dates';

export default {
  name: 'CrewCalendar',
  props: {
    currentDate: {
      type: Date,
      default: () => {
        new Date();
      },
    },
    technician: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    technician(value) {
      if (value) {
        this.users = [value];
        this.loadJobOrders();
      }
    },
  },
  mounted() {
    this.users = [{ id: 0, display_name: 'N/A' }];
  },
  data: () => ({
    currentView: 'day',
    views: ['day', 'week', 'month'],
    groups: ['job_assigned_to_1'],
    users: [],
    dataSource: [],
    jobs: [],
  }),
  methods: {
    getNonWorkingHours(event) {
      const hours = event.startDate.getHours();
      return (hours <= 6 || hours >= 17) && !event.allDay;
    },
    loadJobOrders() {
      this.dataSource = [];

      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      params.filterExt = `job_assigned_to_1=${this.technician.id}`;

      jobOrdersApi
        .list(params)
        .then(({ data }) => {
          if (data.length > 0) {
            const items = data.map((x) => {
              const { job_eta, job_etd, joborder_jobrequest_header: jr } = x;

              x.preview_job_eta = unixToDisplayDateTime(job_eta);
              x.preview_job_etd = unixToDisplayDateTime(job_etd);
              x.preview_time_job_eta = unixToDisplayTime(job_eta);
              x.preview_time_job_etd = unixToDisplayTime(job_etd);
              x.startDate = new UtilsDate(job_eta).unixToDate();
              x.endDate = new UtilsDate(job_etd).unixToDate();
              x.text = `${x.ticket_no} (${x.company_name})`;

              return x;
            });

            this.dataSource = items;

            const jobs = data.map((x) => {
              const { job_status, id } = x;
              x.reference_id = id;

              let color = '';

              if (job_status === '0') {
                color = 'rgb(0 123 255)';
              }

              if (job_status === '1') {
                color = 'rgb(0 207 232)';
              }

              if (job_status === '2') {
                color = 'rgb(255 185 118)';
              }

              if (job_status === '3') {
                color = 'rgb(240 129 130)';
              }

              if (job_status === '4') {
                color = 'rgb(72 218 137)';
              }

              if (job_status === '5') {
                color = 'rgb(156 160 164)';
              }

              x.color = color;

              return x;
            });

            this.jobs = jobs;
          } else {
            this.dataSource = [];
            this.jobs = [];
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style>
.dx-scheduler-time-panel-cell {
  height: 20px;
}

.dx-scheduler-cell-sizes-vertical {
  height: 25px;
}

.disable-date {
  height: 100%;
  width: 100%;
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #9b6467;
}
</style>
